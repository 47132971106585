@import './scss/variables';

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 1279px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: $font;
  font-size: 1rem;
  color: $black;
  margin: 0;
  padding: 0;
  min-width: 1024px;
}
.main {
  padding: 1rem 2rem;
}
.row {
  display: flex;
  min-height: 32px;
  padding: .75rem 0;
  align-items: center;
  border-bottom: $border;

  &-label {
    min-width: 33.33%;
    white-space: nowrap;
    padding-right: 1rem;
  }
}
h1 {
  font-weight: 600;
  margin-bottom: 3rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2rem;
    background-color: $red;
    bottom: -1rem;
    left: 0;
  }
}
h2 {
  font-weight: 700;
}
h3 {
  margin: 2rem 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 1rem;
    background-color: $red;
    bottom: -1rem;
    left: 0;
  }
}
.fixed-column {

  @media screen and (min-width: 1280px) {
    max-width: 960px;
  }

  min-width: 720px;
  margin: 2rem auto;

  .row-label {
    min-width: 50%;
    padding-right: 0;
  }

  .row-input {
    min-width: 50%;
  }

  input[type="number"],select {
    width: 100%;
    box-sizing: border-box;
  }
}

a {
  text-decoration: none !important;
}

button {
  font-size: 0.875rem;
  height: 2.5rem;
  border-radius: 0;
  padding: 0 .75rem 0 .5rem;
  border-radius: 0.3125rem;
  font-weight: 600;
  transition: all .2s ease-out;
  box-sizing: border-box;
  height: 2.5rem;

  a &,
  a:active &,
  a:visited & {
    // color: white;
    text-decoration: none !important;
  }

  svg {
    padding-right: .25rem;
  }

  &:hover,
  &.active {
    background-color: $header;
  }

  &.primary {
    background-color: $red;
    color: white;
    padding-right: .75rem;
    margin-left: .25rem;
    padding: 0 1rem;

    &:hover {
      color: white;
      background-color: lighten($red, 5%);
      svg {
        fill: white;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: .5;
    }

    svg {
      fill: white;
      padding-right: .5rem;
    }
  }

  &.secondary {
    background-color: darken($header, 3%);
    &:hover {
      background-color: $header;
    }
  }
}
